import { ACTIONTYPES } from "../Actions/Types";
import { setCookiesJsonData } from "../../Utils/Helper";

const initialState = {
  Canxbufferdays: 0,
  ShowDashboardlink: false,
  ShowJIRALink: false,
  Dashboardtype: 2,
  ShowRetailMarkupLink: false,
  EnableResortHoppa: false,
  EnableCRM: false,
  ShowEmailCustomerLink: false,
  ShowHCNLink: false,
  ShowSpecialRequestLink: false,
  FlyremitRegistrationStatus: false,
  ShowSecretDeal: false,
  TravelPayPaymentProviders: null,
};

export const AdvanceSettingsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ACTIONTYPES.SET_PARTNER_CONFIG: {
      // setCookiesJsonData("PartnerConfig", payload);
      return {
        ...state,
        Canxbufferdays: payload.Canxbufferdays,
        ShowDashboardlink: payload.ShowDashboardlink,
        ShowJIRALink: payload.ShowJIRALink,
        Dashboardtype: payload.Dashboardtype,
        ShowRetailMarkupLink: payload.ShowRetailMarkupLink,
        EnableResortHoppa: payload.EnableResortHoppa,
        EnableCRM: payload.EnableCRM,
        ShowEmailCustomerLink: payload.ShowEmailCustomerLink,
        ShowHCNLink: payload.ShowHCNLink,
        ShowSpecialRequestLink: payload.ShowSpecialRequestLink,
        HideNationality: payload.lHideNationalityDrpdwn,
        ShowSecretDeal: payload.ShowSecretDeal,
      };
    }

    case ACTIONTYPES.SET_FLYREMIT_REGISTRATION_STATUS: {
      return {
        ...state,
        FlyremitRegistrationStatus: payload,
      };
    }

    case ACTIONTYPES.SET_TRAVELPAY_PAYMENTPROVIDERS: {
      return {
        ...state,
        TravelPayPaymentProviders: payload,
      };
    }

    default:
      return state;
  }
};
