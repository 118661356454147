import axios from "axios";
import { authHeader } from "../Utils/Helper";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = authHeader();
  return config;
});

export default axiosInstance;
