import axios from "axios";
import axiosInstance from "../AxiosInstance";
import { LogoutUser } from "../../Redux/Actions/AuthAction";
import store from "../../Redux/Store";

const status = {
  UNAUTHORIZED: 401,
  OK: 200,
  INTERNAL_SERVER_ERROR: 500,
};

const getAll = async (url, signal) => {
  // let config = null;
  // if (signal) {
  //   config = {
  //     signal: signal,
  //   };
  // }
  return await axiosInstance
    //.get(url, config)
    .get(url)
    .then((response) => {
      if (response.status === status.OK) {
        return {
          success: true,
          data: response.data,
        };
      }
      return { success: false, data: null };
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.status === status.UNAUTHORIZED
      ) {
        store.dispatch(LogoutUser());
      }
      let isCancelled = false;
      // if (signal && axios.isCancel(error)) {
      //   isCancelled = true;
      // }
      return {
        error: true,
        success: false,
        isCancelled: isCancelled,
      };
    });
};

const get = async (url, id) => {
  return await axiosInstance
    .get(url + `/${id}`)
    .then((response) => {
      if (response.status === status.OK) {
        return {
          success: true,
          data: response.data,
        };
      }
      return { success: false, data: null };
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.status === status.UNAUTHORIZED
      ) {
        store.dispatch(LogoutUser());
      }
      return {
        error: true,
        success: false,
      };
    });
};
const post = async (url, data, signal) => {
  let config = null;
  if (signal) {
    config = {
      signal: signal,
    };
  }
  return await axiosInstance
    .post(url, data, config)
    .then((response) => {
      if (response.status === status.OK) {
        return {
          success: true,
          data: response.data,
        };
      }
      return { success: false, data: null, status: response.status };
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.status === status.UNAUTHORIZED
      ) {
        store.dispatch(LogoutUser());
      }

      let isCancelled = false;
      if (signal && axios.isCancel(error)) {
        isCancelled = true;
      }

      return {
        error: true,
        success: false,
        data: error.response ? error.response.data : "",
        status: error.response ? error.response.status : "",
        isCancelled: isCancelled,
      };
    });
};

const update = async (url, id, data) => {
  return await axiosInstance
    .put(url + `/${id}`, data)
    .then((response) => {
      if (response.status === status.OK) {
        return {
          success: true,
          data: response.data,
        };
      }
      return { success: false, data: null };
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.status === status.UNAUTHORIZED
      ) {
        store.dispatch(LogoutUser());
      }
      return {
        error: true,
        success: false,
      };
    });
};

const CommonAPIService = {
  getAll,
  get,
  post,
  update,
};

export { CommonAPIService };
