/* Comments : Vaidehi Shejwalkar.
   Date : 31st Oct 2022
   Functionality : Header and Retail Component
   Developers : Sachin Sarse, Vaidehi Shejwalkar and Dakshineshwar Swain
*/

import React, { useState, useEffect } from "react";
import { Navbar, NavDropdown, Nav, Modal, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { LogoutUser } from "../../../Redux/Actions/AuthAction";
import { setUserRetailMarkup } from "../../../Redux/Actions/RetailInterfaceAction";
import { APPCONSTANTS } from "../../../Constants/AppConstants";

function Header() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const userRetailMarkup = useSelector((state) => state.userRetailMarkup);
  const advanceSettings = useSelector((state) => state.advanceSettings);
  const [showRetailInterface, showRetailInterfaceModal] = useState(false);
  const [retailInterfaceChecked, setRetailInterface] = useState(
    userRetailMarkup.RetailMarkUp > 0 && userRetailMarkup.RetailMarkUp <= 50
      ? true
      : false
  );
  const [retailInterfaceValue, setRetailInterfaceValue] = useState(
    userRetailMarkup.RetailMarkUp
  );

  const [openRedirectPopUp, setopenRedirectPopUp] = useState(false);
  const [navbarExpanded, setNavbarExpanded] = useState(false);

  //Logout User, clear all data from localStorage and Cookies
  const handleLogout = () => {
    dispatch(LogoutUser());
  };

  const ChangeRetailInterface = () => {
    onClickNavLink();
    setRetailInterfaceValue(userRetailMarkup.RetailMarkUp);
    showRetailInterfaceModal(true);
  };
  //#region  Retail Interface

  //Retail Interface
  //Check value entered should be numeric and in between 0 to 50
  const changeRetailInterfaceValue = (e) => {
    const regex = /^[0-9\b]+$/;
    const { value } = e.target;
    if (value === "" || regex.test(value)) {
      let retailMarkup = Number(value);
      if (retailMarkup >= 0 && retailMarkup <= 50)
        setRetailInterfaceValue(retailMarkup);
    }
  };
  useEffect(() => {
    setRetailInterface(
      userRetailMarkup.RetailMarkUp > 0 && userRetailMarkup.RetailMarkUp <= 50
        ? true
        : false
    );
    setRetailInterfaceValue(userRetailMarkup.RetailMarkUp);
  }, [userRetailMarkup.RetailMarkUp]);
  const handleClose = () => showRetailInterfaceModal(false);
  const updateRetailMarkup = () => {
    let setUserRetailMarkupRequest = {
      VisitId: auth.user.VisitId,
      PartnerId: auth.user.PartnerId,
      LoginId: auth.user.LoginId,
      UserId: auth.user.LoginId,
      RetailMarkUp: retailInterfaceValue,
      RebrandSiteId: auth.user.SiteId,
    };
    dispatch(setUserRetailMarkup(setUserRetailMarkupRequest, navigate));
    showRetailInterfaceModal(false);
  };
  //#endregion

  //Contact Us or stuba-selects page should be redirected to wordpress in new tab
  const redirectToWordPress = (url) => {
    if (url === "contact-us") onClickNavLink();
    window.open(
      process.env.REACT_APP_WORDPRESS_URL + "/" + url,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const onNavBarToggle = () => {
    setNavbarExpanded(!navbarExpanded);
  };

  const onClickNavLink = () => {
    if (navbarExpanded) setNavbarExpanded(false);
  };
  return (
    auth.user &&
    auth.user.LoginId !== "" && (
      <>
        <Navbar
          expanded={navbarExpanded}
          // collapseOnSelect
          expand="lg"
          className="navbar navbar-bg-1 navbar-expand-md navbar-dark"
          onToggle={onNavBarToggle}
        >
          <div className="container-fluid">
            <Navbar.Brand as={NavLink} to="Home" onClick={onClickNavLink}>
              <img
                src={
                  userRetailMarkup.LogoURL && userRetailMarkup.RetailMarkUp > 0
                    ? userRetailMarkup.LogoURL.includes("Top.")
                      ? require("../../../Assets/Images/Stuba-TopLogo.png")
                      : userRetailMarkup.LogoURL
                    : require("../../../Assets/Images/Stuba-TopLogo.png")
                }
                alt="Logo"
                width="92"
                height="24"
              ></img>
            </Navbar.Brand>
            {location.pathname !== "/AgencyPaymentRegistration" && (
              <>
                <div className="mobile-user-dropdown">
                  <Nav className="header-nav header-right-2 ms-auto">
                    <Nav className="d-flex align-items-center">
                      <Nav.Item className="nav-item dropdown">
                        <span
                          className="nav-link nav-profile d-flex align-items-center pe-0"
                          // href="#"
                          data-bs-toggle="dropdown"
                        >
                          <svg
                            width="17"
                            height="20"
                            viewBox="0 0 17 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.7542 11.9999C14.9962 11.9999 16.003 13.0068 16.003 14.2488V14.8242C16.003 15.7185 15.6835 16.5833 15.1019 17.2627C13.5326 19.0962 11.1454 20.0011 8 20.0011C4.85414 20.0011 2.46812 19.0959 0.90182 17.2617C0.322059 16.5828 0.00354004 15.7193 0.00354004 14.8265V14.2488C0.00354004 13.0068 1.0104 11.9999 2.25242 11.9999H13.7542ZM13.7542 13.4999H2.25242C1.83882 13.4999 1.50354 13.8352 1.50354 14.2488V14.8265C1.50354 15.3622 1.69465 15.8802 2.04251 16.2876C3.29582 17.7553 5.26169 18.5011 8 18.5011C10.7383 18.5011 12.7059 17.7553 13.9624 16.2873C14.3113 15.8797 14.503 15.3608 14.503 14.8242V14.2488C14.503 13.8352 14.1678 13.4999 13.7542 13.4999ZM8 0.00463867C10.7614 0.00463867 13 2.24321 13 5.00464C13 7.76606 10.7614 10.0046 8 10.0046C5.23857 10.0046 3 7.76606 3 5.00464C3 2.24321 5.23857 0.00463867 8 0.00463867ZM8 1.50464C6.067 1.50464 4.5 3.07164 4.5 5.00464C4.5 6.93764 6.067 8.50464 8 8.50464C9.93299 8.50464 11.5 6.93764 11.5 5.00464C11.5 3.07164 9.93299 1.50464 8 1.50464Z"
                              fill="white"
                            />
                          </svg>
                          <NavDropdown
                            id="collasible-nav-dropdown"
                            className="ps-2 dropdown-menu-end dropdown-menu-arrow profile nav-profile_lable"
                          >
                            <NavDropdown.Item
                              as={NavLink}
                              to="AgencyDetails"
                              // href="#AgencyDetails"
                              className="dropdown-item d-flex align-items-center"
                              onClick={onClickNavLink}
                            >
                              Agency Details
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={NavLink}
                              to="FinanceDetails"
                              // href="#5"
                              className="dropdown-item d-flex align-items-center"
                            >
                              Finance Details
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              as={NavLink}
                              to="stuba-selects"
                              onClick={() =>
                                redirectToWordPress("stuba-selects")
                              }
                              // href="#5"
                              className="dropdown-item d-flex align-items-center"
                            >
                              Stuba Selects
                            </NavDropdown.Item>
                            <NavDropdown.Item
                              onClick={handleLogout}
                              className="dropdown-item d-flex align-items-center"
                            >
                              Log Out
                            </NavDropdown.Item>
                          </NavDropdown>
                        </span>
                      </Nav.Item>
                    </Nav>
                  </Nav>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav">
                    <svg
                      width="24"
                      height="18"
                      viewBox="0 0 24 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="24" height="2" rx="1" fill="white" />
                      <rect y="8" width="24" height="2" rx="1" fill="white" />
                      <rect y="16" width="24" height="2" rx="1" fill="white" />
                    </svg>
                  </Navbar.Toggle>
                </div>

                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="header-menu mb-2 mb-md-0">
                    <Nav.Link
                      // href="#dashboard"
                      as={NavLink}
                      to="Home"
                      className="nav-item "
                      onClick={onClickNavLink}
                    >
                      Dashboard
                    </Nav.Link>
                    {/* <Nav.Link as={Link} to="Stays" href="#1" className="nav-item ">
              Stays
            </Nav.Link> */}
                    <Nav.Link
                      // href="#manageBookings"
                      as={NavLink}
                      to="ManageBookings"
                      className="nav-item "
                      onClick={onClickNavLink}
                    >
                      Manage Bookings
                    </Nav.Link>
                    {advanceSettings.EnableResortHoppa && (
                      <Nav.Link
                        //href="#transfer"
                        as={NavLink}
                        target="_blank"
                        to="Transfer"
                        className="nav-item headerInActiveLink"
                        onClick={onClickNavLink}
                      >
                        Transfers
                      </Nav.Link>
                    )}
                    <Nav.Link
                      // href="#contact"
                      as={NavLink}
                      to="Contact"
                      className="nav-item headerInActiveLink"
                      onClick={() => redirectToWordPress("contact-us")}
                    >
                      Contact Us
                    </Nav.Link>
                  </Nav>

                  {/* Retail Interface Toggle */}
                  <div className="d-flex header-right-1">
                    {/* Check Partner/Agent Advance Settings in Back Office and if Enable Retail Markup is set to Yes then show the Retail Interface Toggle else hide it.*/}
                    {advanceSettings.ShowRetailMarkupLink && (
                      <div className="form-check form-switch retailInterfacebtn">
                        <input
                          title="Set Retail Interface"
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={retailInterfaceChecked}
                          onChange={ChangeRetailInterface}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Retail Interface
                        </label>
                      </div>
                    )}
                    <span className="header-broder-1"></span>
                    <Nav className="header-nav header-right-2 ms-auto desktop-user-dropdown">
                      <Nav className="d-flex align-items-center">
                        <Nav.Item className="nav-item dropdown">
                          <span
                            className="nav-link nav-profile d-flex align-items-center pe-0"
                            // href="#"
                            data-bs-toggle="dropdown"
                          >
                            <svg
                              width="17"
                              height="20"
                              viewBox="0 0 17 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.7542 11.9999C14.9962 11.9999 16.003 13.0068 16.003 14.2488V14.8242C16.003 15.7185 15.6835 16.5833 15.1019 17.2627C13.5326 19.0962 11.1454 20.0011 8 20.0011C4.85414 20.0011 2.46812 19.0959 0.90182 17.2617C0.322059 16.5828 0.00354004 15.7193 0.00354004 14.8265V14.2488C0.00354004 13.0068 1.0104 11.9999 2.25242 11.9999H13.7542ZM13.7542 13.4999H2.25242C1.83882 13.4999 1.50354 13.8352 1.50354 14.2488V14.8265C1.50354 15.3622 1.69465 15.8802 2.04251 16.2876C3.29582 17.7553 5.26169 18.5011 8 18.5011C10.7383 18.5011 12.7059 17.7553 13.9624 16.2873C14.3113 15.8797 14.503 15.3608 14.503 14.8242V14.2488C14.503 13.8352 14.1678 13.4999 13.7542 13.4999ZM8 0.00463867C10.7614 0.00463867 13 2.24321 13 5.00464C13 7.76606 10.7614 10.0046 8 10.0046C5.23857 10.0046 3 7.76606 3 5.00464C3 2.24321 5.23857 0.00463867 8 0.00463867ZM8 1.50464C6.067 1.50464 4.5 3.07164 4.5 5.00464C4.5 6.93764 6.067 8.50464 8 8.50464C9.93299 8.50464 11.5 6.93764 11.5 5.00464C11.5 3.07164 9.93299 1.50464 8 1.50464Z"
                                fill="white"
                              />
                            </svg>
                            <NavDropdown
                              title={
                                auth && auth.user && auth.user.LoginId
                                  ? "Hi " + auth.user.UserName
                                  : "Hi"
                              }
                              id="collasible-nav-dropdown"
                              className="ps-2 dropdown-menu-end dropdown-menu-arrow profile nav-profile_lable"
                            >
                              <NavDropdown.Item
                                as={NavLink}
                                to="AgencyDetails"
                                // href="#5"
                                className="dropdown-item d-flex align-items-center"
                              >
                                Agency Details
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                as={NavLink}
                                to="FinanceDetails"
                                // href="#5"
                                className="dropdown-item d-flex align-items-center"
                              >
                                Finance Details
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                as={NavLink}
                                to="stuba-selects"
                                onClick={() =>
                                  redirectToWordPress("stuba-selects")
                                }
                                // href="#5"
                                className="dropdown-item d-flex align-items-center"
                              >
                                Stuba Selects
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                onClick={handleLogout}
                                className="dropdown-item d-flex align-items-center"
                              >
                                Log Out
                              </NavDropdown.Item>
                            </NavDropdown>
                          </span>
                        </Nav.Item>
                      </Nav>
                    </Nav>
                  </div>
                </Navbar.Collapse>
              </>
            )}
          </div>
          {/* Retail Interface UI*/}
          <Modal
            id="RetailInterface"
            show={showRetailInterface}
            onHide={handleClose}
            centered
          >
            <Modal.Header>
              <Modal.Title>Retail Interface</Modal.Title>
              <button
                type="button"
                className="btn-close-1"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.25 0C1.45507 0 0 1.45508 0 3.25V14.75C0 16.5449 1.45508 18 3.25 18H14.75C16.5449 18 18 16.5449 18 14.75V3.25C18 1.45507 16.5449 0 14.75 0H3.25ZM5.21967 5.21967C5.51256 4.92678 5.98744 4.92678 6.28033 5.21967L9 7.93934L11.7197 5.21967C12.0126 4.92678 12.4874 4.92678 12.7803 5.21967C13.0732 5.51256 13.0732 5.98744 12.7803 6.28033L10.0607 9L12.7803 11.7197C13.0732 12.0126 13.0732 12.4874 12.7803 12.7803C12.4874 13.0732 12.0126 13.0732 11.7197 12.7803L9 10.0607L6.28033 12.7803C5.98744 13.0732 5.51256 13.0732 5.21967 12.7803C4.92678 12.4874 4.92678 12.0126 5.21967 11.7197L7.93934 9L5.21967 6.28033C4.92678 5.98744 4.92678 5.51256 5.21967 5.21967Z"
                    fill="#384860"
                  />
                </svg>
              </button>
            </Modal.Header>
            <Modal.Body>
              <p>
                {userRetailMarkup.RetailMarkUp > 0 &&
                userRetailMarkup.RetailMarkUp <= 50
                  ? "To deactivate the retail interface, please set the markup to 0."
                  : "To activate the retail interface, please set the markup is between 1 and 50 percent."}
              </p>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter"
                  value={retailInterfaceValue}
                  onChange={changeRetailInterfaceValue}
                />
                <span className="input-group-text">%</span>
              </div>
              <p>
                (Retail Markup will be saved for future sessions, unless you
                change or remove it. Retail Markup will not apply on transfer
                bookings.)
              </p>
              <p style={{ color: "#0378d6" }}>
                You will need to make a new search for the updated retail mark
                up to apply.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <input
                type="button"
                title="Close"
                name="close"
                className="previous RetailInterface-close-btn-2"
                onClick={handleClose}
                value="Close"
              />
              <Button
                title="Set"
                variant="primary RetailInterface-set-btn-2"
                disabled={
                  (retailInterfaceValue > 0 && retailInterfaceValue <= 50) ||
                  retailInterfaceValue == 0
                    ? false
                    : true
                }
                onClick={updateRetailMarkup}
              >
                Set
              </Button>
            </Modal.Footer>
          </Modal>
        </Navbar>
      </>
    )
  );
}
export default Header;
