/* eslint-disable no-unused-expressions */
import * as XLSX from "xlsx";
import Cookies from "universal-cookie";
import DOMPurify from "dompurify";

export const setStorageJsonData = (key, data) => {
  if (key === "searchRequest") localStorage.setItem(key, JSON.stringify(data));
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getStorageJsonParseData = (key) => {
  let data = sessionStorage.getItem(key);
  if (data) return JSON.parse(sessionStorage.getItem(key));
  else if (key === "searchRequest")
    return JSON.parse(localStorage.getItem(key));
};

export const setLocalStorageData = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageData = (key) => {
  return localStorage.getItem(key);
};

export const setStorageData = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const getStorageData = (key) => {
  return sessionStorage.getItem(key);
};

export const setCookiesJsonData = (key, data) => {
  var cookies = new Cookies();
  cookies.set(key, JSON.stringify(data), {
    path: "/",
    expires: 0,
    sameSite: "none",
    secure: true,
  });
};

export const setCookiesData = (key, value) => {
  var cookies = new Cookies();
  cookies.set(key, value, {
    path: "/",
    expires: 0,
    sameSite: "none",
    secure: true,
  });
};

export const getCookiesData = (key) => {
  var cookies = new Cookies();
  return cookies.get(key);
};

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 300);
  };
};

export function authHeader() {
  // return authorization header with jwt token
  // let token = getStorageData("token");
  var cookies = new Cookies();
  let token = cookies.get("Token");
  if (token) {
    return { Authorization: "Bearer " + token };
  } else {
    return {};
  }
}

export const DisplayPDF = (href) => {
  // Append to html link element page
  const link = document.createElement("a");
  link.href = href;
  //link.setAttribute("download", `FileName.pdf`);
  link.setAttribute("target", "_blank");
  link.style.visibility = "hidden";
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const ExportToXlsx = (data, filename) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  XLSX.writeFile(wb, filename + ".xlsx");
};

export const isEmptyObject = (value) => {
  if (value === null || value === undefined) return true;
  return Object.keys(value).length === 0 && value.constructor === Object;
};

export const setLoginCookies = (data) => {
  if (data && data.cookies) {
    const cookies = new Cookies();
    cookies.set("LoginId", data.LoginId, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("VisitId", data.VisitId, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("SeqNumber", data.cookies.SeqNumber, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("PartnerName", data.cookies.PartnerName, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("UserName", data.cookies.UserName, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("PartnerId", data.cookies.PartnerId, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("ParentId", data.cookies.ParentId, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("SiteId", data.cookies.SiteId, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("SessionId", data.cookies.SessionId, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("RealName", data.cookies.RealName, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    //Token
    cookies.set("Token", data.Token, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    // PartnerConfig = Advanced Settings is used for show/hide features for an Agent/Partner and can be set from Back Office.  The reason for keeping it in cookies is because data will be present even after page refresh.
    cookies.set("PartnerConfig", JSON.stringify(data.PartnerConfig), {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    // LogoURL is used to show Agent/Partner logo.  The reason for keeping it in cookies is because data will be present even after page refresh.
    cookies.set("LogoURL", data.LogoURL, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    // Used for Commission Agent.  The reason for keeping it in cookies is because data will be present even after page refresh.
    cookies.set("Commissionable", data.Commissionable, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    // Used for showing user retail markup. The reason for keeping it in cookies is because data will be present even after page refresh.
    cookies.set("RetailMarkUp", data.UserRetailMarkupPrice, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    //DefaultNationality
    cookies.set("NationalityCode", data.NationalityCode, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("HideNationalityDropdown", data.HideNationality, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("BussinessUnitId", data.BussinessUnitId, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
    cookies.set("isBUWiseData", data.isPdfPresent, {
      path: "/",
      expires: 0,
      sameSite: "none",
      secure: true,
    });
  }
};

export const getLoginCookies = () => {
  const cookies = new Cookies();
  const loginId = cookies.get("LoginId");
  if (loginId) {
    return {
      LoginId: loginId,
      VisitId: cookies.get("VisitId"),
      SeqNumber: cookies.get("SeqNumber"),
      PartnerName: cookies.get("PartnerName"),
      UserName: cookies.get("UserName"),
      PartnerId: cookies.get("PartnerId"),
      ParentId: cookies.get("ParentId"),
      SiteId: cookies.get("SiteId"),
      SessionId: cookies.get("SessionId"),
      RealName: cookies.get("RealName"),
      NationalityCode: cookies.get("NationalityCode"),
      BussinessUnitId: cookies.get("BussinessUnitId"),
      isBUWiseData: cookies.get("isBUWiseData") === "true" ? true : false,
      Commissionable: cookies.get("Commissionable") === "true" ? true : false,
    };
  }
  return null;
};

export const removeLoginCookies = () => {
  const cookies = new Cookies();
  cookies.remove("LoginId");
  cookies.remove("VisitId");
  cookies.remove("SeqNumber");
  cookies.remove("PartnerName");
  cookies.remove("UserName");
  cookies.remove("PartnerId");
  cookies.remove("ParentId");
  cookies.remove("SiteId");
  cookies.remove("SessionId");
  cookies.remove("RealName");
  cookies.remove("RetailMarkUp");
  cookies.remove("LogoURL");
  cookies.remove("PartnerConfig");
  cookies.remove("Commissionable");
  cookies.remove("Token");
  cookies.remove("NationalityCode");
  cookies.remove("HideNationalityDropdown");
  cookies.remove("BussinessUnitId");
  cookies.remove("isBUWiseData");
};

export const removeLocalCheckoutData = () => {
  sessionStorage.removeItem("Rooms");
  sessionStorage.removeItem("isAddHotel");
  sessionStorage.removeItem("bookingRef");
  sessionStorage.removeItem("paymentMode");
  sessionStorage.removeItem("connectorId");
  sessionStorage.removeItem("paymentFrom");
  sessionStorage.removeItem("guestsObject");
  sessionStorage.removeItem("refreshAvailability");
  sessionStorage.removeItem("AgentRef");
  sessionStorage.removeItem("RoomDetails");
  sessionStorage.removeItem("CancellationPolicy");
  sessionStorage.removeItem("providerShortCode");
};

export const removeStorageDataByKey = (key) => {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
};

export const sanitizedData = (data) => ({
  __html: DOMPurify.sanitize(data),
});

export const setPrefilledLoginCookies = (data) => {
  const cookies = new Cookies();
  cookies.set("loginAgency", data.loginAgency, {
    path: "/",
    expires: 0,
    sameSite: "none",
    secure: true,
  });
  cookies.set("loginUserName", data.loginUserName, {
    path: "/",
    expires: 0,
    sameSite: "none",
    secure: true,
  });
};

export const clearStorage = () => {
  let loginAgency = getLocalStorageData("loginAgency");
  let loginUserName = getLocalStorageData("loginUserName");
  localStorage.clear();
  sessionStorage.clear();
  if (loginAgency) setLocalStorageData("loginAgency", loginAgency);
  if (loginUserName) setLocalStorageData("loginUserName", loginUserName);
};

//This function encapsulates the logic for checking whether the user is viewing the site from mobile or desktop browser.
export const isViewedOnMobile = () => {
  let isMobile = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      isMobile = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return isMobile;
};
