import { ACTIONTYPES } from "./Types";
import { CommonAPIService } from "../../Services/CommonService";
import { GET_PARTNER_CONFIG } from "../../Services/APIUrl";

export const setPartnerConfig = (request) => async (dispatch) => {
  const response = await CommonAPIService.post(GET_PARTNER_CONFIG, request);
  if (response.success && response.data) {
    dispatch({ type: ACTIONTYPES.SET_PARTNER_CONFIG, payload: response.data });
  }
};

export const setPartnerConfigOnRefresh = (data) => async (dispatch) => {
  dispatch({ type: ACTIONTYPES.SET_PARTNER_CONFIG, payload: data });
};

export const setFlyremitRegistrationStatus = (data) => async (dispatch) => {
  dispatch({
    type: ACTIONTYPES.SET_FLYREMIT_REGISTRATION_STATUS,
    payload: data,
  });
};

export const setTravelpayPaymentProviders = (data) => async (dispatch) => {
  dispatch({
    type: ACTIONTYPES.SET_TRAVELPAY_PAYMENTPROVIDERS,
    payload: data,
  });
};
