import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import Skeleton from "react-loading-skeleton";

import "bootstrap/dist/css//bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";

import "./Assets/CSS/style.css";
import "./Assets/CSS/responsive.css";
import "./Assets/CSS/custom.css";
import "./Assets/CSS/mobile-style.css";

import "bootstrap-icons/font/bootstrap-icons.css";
import "react-image-gallery/styles/css/image-gallery.css";

// import "./I18n";
import App from "./App";
import store from "./Redux/Store";

ReactDOM.render(
  <Provider store={store}>
    <Suspense
      fallback={
        <div className=" paading_10 text-center">
          <Skeleton height={70}></Skeleton>
          <Skeleton height={70}></Skeleton>
          <Skeleton height={70}></Skeleton>
          <Skeleton height={70}></Skeleton>
          <Skeleton height={70}></Skeleton>
        </div>
      }
    >
      <Router>
        <App />
      </Router>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
