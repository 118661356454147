import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLoginUser } from "./Redux/Actions/AuthAction";
import {
  setHotelSearchRequestOnRefresh,
  setHomeSearchRequest,
  setStaySearchRequest,
} from "./Redux/Actions/HotelSearchAction";
import { setUserRetailMarkupOnRefresh } from "./Redux/Actions/RetailInterfaceAction";
import { setPartnerConfigOnRefresh } from "./Redux/Actions/AdvanceSettingsAction";
import Header from "./Components/Common/Header";
import Footer from "./Components/Common/Footer";
import Routes from "./Routes";
import { getLoginCookies, getCookiesData } from "./Utils/Helper";

const App = (props) => {
  const [thirdPartyCookiesEnabled, setCookiesEnabled] = useState(true);
  const auth = useSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    let cookiesEnabled = true;
    try {
      if (window.localStorage) {
        cookiesEnabled = true;
      }
    } catch (err) {
      cookiesEnabled = false;
    }
    setCookiesEnabled(cookiesEnabled);
    if (auth.user.LoginId === "" && cookiesEnabled) {
      const loginResponse = getLoginCookies();
      if (loginResponse) {
        dispatch(setLoginUser(loginResponse));
        let partnerConfig = getCookiesData("PartnerConfig");
        if (partnerConfig) dispatch(setPartnerConfigOnRefresh(partnerConfig));
        let retailMarkUp = getCookiesData("RetailMarkUp");
        if (retailMarkUp)
          dispatch(
            setUserRetailMarkupOnRefresh({
              RetailMarkUp: Number(retailMarkUp),
              LogoURL: getCookiesData("LogoURL"),
            })
          );
        let nationalityCode = getCookiesData("NationalityCode");

        if (nationalityCode) {
          dispatch(
            setHomeSearchRequest({
              type: "nationality",
              NationalityCode: nationalityCode,
              NationalityCodeLabel: "",
            })
          );
          dispatch(
            setStaySearchRequest({
              type: "nationality",
              NationalityCode: nationalityCode,
              NationalityCodeLabel: "",
            })
          );
        }
        dispatch(setHotelSearchRequestOnRefresh());
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className="d-flex flex-column min-vh-100">
        {thirdPartyCookiesEnabled ? (
          <>
            <div className="header-main">
              {location.pathname === "/Login" ||
              location.pathname === "/Transfer" ||
              location.pathname === "/TransferRedirect" ? (
                <></>
              ) : (
                <Header></Header>
              )}
              <Routes />
            </div>
            {location.pathname === "/Login" ||
            location.pathname === "/Transfer" ||
            location.pathname === "/TransferRedirect" ? (
              <></>
            ) : (
              <Footer></Footer>
            )}
          </>
        ) : (
          <div className="cookiesBlockedMsg">
            <h4>Third Party cookies are blocked.</h4>
            <button
              className="btn clickHereBtn"
              onClick={() =>
                window.open(
                  process.env.REACT_APP_HOSTED_URL,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Click Here
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default App;
