import { ACTIONTYPES } from "../Actions/Types";
import { setStorageJsonData } from "../../Utils/Helper";

const initialState = {
  showLoading: false,
  refreshAvailabilityResponse: {},
  refreshAvailabilityRequest: {
    QuoteIndex: -1,
    SearchId: -1,
    VisitId: -1,
    PartnerId: -1,
    LoginId: -1,
    SellingPrice: "",
  },
  hotelDetails: {
    HotelName: "",
    HotelId: -1,
    Adults: [],
    Childs: [],
    RoomCount: [],
    RoomType: "",
    BoardType: "",
    Region: "",
    Location: "",
    ArrivalDate: Date(),
    DepartureDate: Date(),
    TURating: "",
    HotelType: "",
    SellingPrice: "",
  },
};

export const RefreshAvailabilityReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ACTIONTYPES.SET_REFRESHAVAILABILITY_REQUEST:
      let request = {
        QuoteIndex: payload.refreshAvailabilityRequest.QuoteIndex,
        SearchId: payload.refreshAvailabilityRequest.SearchId,
        VisitId: payload.refreshAvailabilityRequest.VisitId,
        PartnerId: payload.refreshAvailabilityRequest.PartnerId,
        LoginId: payload.refreshAvailabilityRequest.LoginId,
      };
      setStorageJsonData("refreshAvailability", request);
      setStorageJsonData("RoomDetails", payload.hotelDetails);
      return {
        ...state,
        refreshAvailabilityRequest: request,
        hotelDetails: payload.hotelDetails,
      };

    case ACTIONTYPES.SET_REFRESHAVAILABILITY_RESULT:
      setStorageJsonData("CancellationPolicy", payload);

      return { ...state, refreshAvailabilityResponse: payload };

    default:
      return state;
  }
};
