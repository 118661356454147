export const ACTIONTYPES = {
  //Login
  SET_LOGIN_USER: "SET_LOGIN_USER",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  LOGOUT_USER: "LOGOUT_USER",
  //Perform Search
  SET_HOTELSEARCH_PARAMETERS: "SET_HOTELSEARCH_PARAMETERS",
  SET_HOTELSEARCH_REQUEST: "SET_HOTELSEARCH_REQUEST",
  SET_HOTELSEARCH_RESULT: "SET_HOTELSEARCH_RESULT",
  SET_FILTERS: "SET_FILTERS",
  SET_HOME_SEARCH_REQUEST: "SET_HOME_SEARCH_REQUEST",
  SET_STAY_SEARCH_REQUEST: "SET_STAY_SEARCH_REQUEST",
  REMOVE_FILTERS: "REMOVE_FILTERS",
  SET_HOTELSEARCH_REQUEST_ONREFRESH: "SET_HOTELSEARCH_REQUEST_ONREFRESH",
  SET_REFUNDABLE: "SET_REFUNDABLE",
  SET_HOME_SERACH_VALIDATION_ERROR: "SET_HOME_SERACH_VALIDATION_ERROR",
  SET_NATIONALITYLIST: "SET_NATIONALITYLIST",
  CLEAR_SEARCHRESPONSE: "CLEAR_SEARCHRESPONSE",
  SET_SELECTEDHOTEL: "SET_SELECTEDHOTEL",
  SET_VIEWALLCATEGORY: "SET_VIEWALLCATEGORY",

  //RefreshAvailabilty
  SET_REFRESHAVAILABILITY_REQUEST: "SET_REFRESHAVAILABILITY_REQUEST",
  SET_REFRESHAVAILABILITY_RESULT: "SET_REFRESHAVAILABILITY_RESULT",
  SET_ROOM_DESCRIPTION: "SET_ROOM_DESCRIPTION",
  //GuestObject
  SET_ROOMS_REQUEST: "SET_ROOMS_REQUEST",
  SET_ROOM_OBJECT: "SET_ROOM_OBJECT",
  SET_POLICYACCEPTED: "SET_POLICYACCEPTED",
  //Confirm Booking
  SET_CONFIRMGUESTDETAILS_RESULT: "SET_CONFIRMGUESTDETAILS_RESULT",
  //Retail Interface
  SET_USER_RETAIL_MARKUP: "SET_USER_RETAIL_MARKUP",
  USER_RETAIL_MARKUP_SUCCESS: "USER_RETAIL_MARKUP_SUCCESS",
  USER_RETAIL_MARKUP_FAILURE: "USER_RETAIL_MARKUP_FAILURE",
  //Partner
  SET_PARTNER_CONFIG: "SET_PARTNER_CONFIG",
  SET_FLYREMIT_REGISTRATION_STATUS: "SET_FLYREMIT_REGISTRATION_STATUS",
  SET_TRAVELPAY_PAYMENTPROVIDERS: "SET_TRAVELPAY_PAYMENTPROVIDERS",
};
