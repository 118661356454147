import React from "react";
import moment from "moment";

export default function Footer() {
  return (
    <div className="footer-main mt-auto">
      <footer className="footer-main-1">
        <div className="container">
          <div className="row">
            <a href="home">
              <img
                src={require("../../../Assets/Images/Stuba-BottomLogo.png")}
                alt=""
              />
            </a>
            <h6>Stuba is ISO 9001:2015 Certified</h6>
            <h5>© {moment().format("YYYY")} Stuba.com</h5>
          </div>
        </div>
      </footer>
    </div>
  );
}
