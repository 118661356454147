//SignalR
export const GET_SIGNALR_CONNECTION_URL = "/hubs/searchHub";
//PreLogin and Login
export const LOGINURL = "/partner/dologin";
//Home and Stay
export const GET_SEARCHDETAILS_RECENTSEARCH =
  "/availabilitysearch/getHotelSearchDetailsForRecentSearch";
export const GET_RECENTSEARCHES = "/availabilitysearch/getRecentSearches/";
export const GET_DESTINATIONS = "/availabilitysearch/getDestinationDropdown/";
export const GET_ALLNATIONALITIES = "/availabilitySearch/getAllNationalities";
export const GET_PERFORMSEARCH = "/availabilitysearch/getPerformsearch";
export const GET_DASHBOARD_COUNTS = "/booking/getDashboardCounts";
//Hotel and Room Descriptions
export const GET_HOTEL_OVERVIEW = "/availabilitySearch/getHotelDescriptions";
export const GET_HOTEL_TU_REVIEWS = "/availabilitySearch/getHotelTUReviews";
export const GET_ROOM_DESCRIPTIONS = "/availabilitySearch/getRoomDescriptions";
//Refresh Availability
export const GET_REFRESH_AVAILABILTY =
  "/availabilitysearch/getRefreshavailability";
export const GET_REFRESH_HOTEL = "/availabilitysearch/getRefreshHotel";
//Guest Details
export const GET_GUEST_ITEM_IDS = "/booking/getGuests";
export const GET_CONFIRM_GUEST_DETAILS = "/booking/getConfirmDetails";
//Check Out/Confirm Booking
export const GET_CONFIRM_BOOKING = "/booking/confirmbooking";
export const GET_PAYMENT_INTIALDATE = "/booking/confirmbooking";
export const VALIDATE_PAYMENT = "/booking/ValidatePayment";
export const GET_CREDITCARD_DETAILS = "/booking/GetCreditCardDetails";

//Cancel Booking
export const CANCEL_BOOKING = "/booking/CancelBooking";

//Bookings
export const GET_RECENTBOOKINGS = "/Booking/getRecentBookings";
export const GET_UNPAIDBOOKINGS = "/Booking/getConfirmedUnpaidBookings";
export const GET_CANCELLATIONDEADLINE =
  "/Booking/getEnteringCancellationDeadline";
export const GET_ALLBOOKINGS = "/Booking/getBookingSearchList";
export const GET_BOOKINGSDETAILS = "/Booking/getBookingDetails";
export const GET_HCNFORNEXT7DAYS = "/Booking/getHCNForNext7Days";
export const GET_COMPLETED_SERVICEREQUESTS = "/CRM/getServiceCases";
export const GET_ALL_SERVICEREQUESTS = "/CRM/getServiceCases";

export const DISPLAY_VOUCHER = "/Booking/getVoucher";
export const ISSUE_VOUCHER = "/Booking/issueVoucher";
export const DISPLAY_INVOICE = "/Booking/getInvoice";
export const SAVE_AGENTREF = "/Booking/addedituserref";
export const DOWNLOAD_BANKDETAILS = "/Partner/downloadBankDetails";
export const GET_RESORTHOPPAURL = "/Booking/getresorthoppaurl";
export const RESORTHOPPABOOKING = "/Booking/resorthoppabookingasync";

//CRM
export const GET_RECENT_SERVICEREQUESTS = "/CRM/getServiceCases";
export const GET_ACTIVE_SERVICEREQUESTS = "/CRM/getServiceCases";
export const GET_SERVICE_CASE_DETAILS = "/CRM/getDisplayServiceCaseDetails";

//QPush
export const SEND_QUOTE_MAIL = "/Booking/sendQPushMail";

//Partner
export const GET_PARTNER_CONFIG = "/Partner/getPartnerConfig";
export const GET_PARTNER_DETAILS = "/Partner/getPartner";
export const GET_AGENCY_DETAILS = "/Partner/getAgencyDetails";
export const ADD_PARTNER_DETAILS = "/Partner/addPartner";
export const UPDATE_PARTNER_DETAILS = "/Partner/updatePartner";
export const GET_FLYREMIT_REGISTRATION_STATUS =
  "/Partner/getFlyremitRegistrationStatus";
export const GET_FLYREMIT_REGISTRATION_URL =
  "/Partner/getFlyremitRegistrationURL";
export const GET_PARTNER_FINANCIAL_DETAILS = "/Partner/getPartnerFinancialInfo";
export const GET_TRAVELPAY_CUSTOMERDETAILS = "/Partner/getCustomerDetails";
export const SAVE_TRAVELPAY_CIN = "/Partner/saveTravelPayCustomerReference";
export const GET_TRAVELPAYPAYMENTPROVIDERS =
  "/Partner/getTravelPayPaymentProvider";
export const UPDATE_TRAVELPAY_CUSTOMERREFERENCE =
  "/Partner/updateTravelPayCustomerReference";

//User
export const GET_USER_DETAILS = "/Partner/getUserDetails";
export const GET_CRMBOOKINGSDETAILS = "/CRM/getBookingDetails";
export const GET_CaseDetailsList = "/CRM/getCaseDetailsList";
export const ADD_CRMCASE = "/CRM/saveCaseDetails";
export const ADD_ServiceCaseComment = "/CRM/addServiceCaseComment";
export const GET_LOGIN_USER_DETAILS = "/Partner/getLoginUserDetails";
export const GET_ALL_USER_DETAILS = "/Partner/getAllUserDetails";
export const ADD_USER_DETAILS = "/Partner/addUserDetails";
export const UPDATE_USER_DETAILS = "/Partner/updateUserDetails";
export const DELETE_USER_DETAILS = "/Partner/deleteUserDetails";
export const GET_USER_RETAIL_MARKUP = "/Partner/getUserRetailMarkup";
export const UPDATE_USER_RETAIL_MARKUP = "/Partner/updateUserRetailMarkup";
export const UPDATE_LOGO = "/Partner/updateLogo";
export const RESET_PASSWORD = "/Partner/resetPassword";

//OTP
export const SEND_OTP = "/Partner/sendOTP";
export const VALIDATE_OTP = "/Partner/validateOTP";
export const SEND_PASSWORD_RESET_OTP = "/Partner/sendPasswordResetOTP";
export const VALIDATE_PASSWORD_RESET_OTP = "/Partner/validatePasswordResetOTP";
