import moment from "moment";

const Request = {
  searchRequest: {
    ForCuration: true,
    HotelName: "",
    HotelId: 0,
    BrandIds: [],
    CategoryIds: [],
    LocationId: -1,
    LandmarkIds: "",
    DestinationGeoCodes: "",
    DestinationGeoCodesName: "",
    MaxPrice: -1,
    MinPrice: -1,
    MealTypeIds: [],
    NewRatings: [],
    RegionName: "",
    RegionId: 0,
    NationalityCode: "",
    NationalityCodeLabel: "",
    RoomCount: [],
    AdultCount: [],
    ChildCount: [],
    ChildAges: [],
    VisitId: 0,
    LoginId: 0,
    PartnerId: 0,
    ArrivalDate: moment().toDate(),
    DepartureDate: moment().add(1, "d").toDate(),
    Rooms: [],
    DistrictIds: [],
    HotelTypeIds: [],
    Ratings: [],
    PricingMode: "Default",
    AmenityIds: [],
    AmenityIdsForUi: [],
    AmenitySortIds: [],
    A_Day: moment().date(),
    A_Month: moment().month() + 1,
    A_Year: moment().year(),
    D_Day: moment().add(1, "d").date(),
    D_Month: moment().add(1, "d").month() + 1,
    D_Year: moment().add(1, "d").year(),
    CurrencyId: 1,
    SearchId: -1,
    PageNumber: 1,
    Commissionable: false,
    IsSecretDealAgent: false,
    IsRetailAgent: false,
  },
  subRequest: {
    HotelName: "",
    HotelId: 0,
    RegionName: "",
    RegionId: 0,
    ArrivalDate: moment().toDate(),
    DepartureDate: moment().toDate(),
    A_Day: moment().date(),
    A_Month: moment().month() + 1,
    A_Year: moment().year(),
    D_Day: moment().add(1, "d").date(),
    D_Month: moment().add(1, "d").month() + 1,
    D_Year: moment().add(1, "d").year(),
    RoomCount: 0,
    AdultCount: [],
    ChildCount: [],
    ChildAges: [],
    NationalityCode: "",
    NationalityCodeLabel: "",
    PageNumber: 1,
  },
  filterRequest: {
    HotelName: "",
    HotelId: 0,
    LandmarkIds: "",
    DestinationGeoCodes: "",
    DestinationGeoCodesName: "",
    MinPrice: -1,
    MaxPrice: -1,
    NewRatings: [],
    DistrictIds: [],
    BrandIds: [],
    CategoryIds: [],
    HotelTypeIds: [],
    parameterNameFilterCount: 0,
    starRatingFilterCount: 0,
    locationFilterCount: 0,
    moreFilterCount: 0,
    PageNumber: 1,
    MealTypeIds: [],
  },
};

export default Request;
