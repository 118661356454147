import clone from "just-clone";
import { ACTIONTYPES } from "../Actions/Types";
import {
  setStorageJsonData,
  getStorageJsonParseData,
} from "../../Utils/Helper";
import Request from "../../Constants/SearchRequest";
import moment from "moment";

const initialState = {
  showLoading: false,
  searchResponse: {},
  filters: [],
  homeSearchRequest: {
    HotelName: "",
    HotelId: 0,
    RegionName: "",
    RegionId: 0,
    ArrivalDate: moment(),
    DepartureDate: moment().add(1, "d"),
    A_Day: moment().date(),
    A_Month: moment().month() + 1,
    A_Year: moment().year(),
    D_Day: moment().add(1, "d").date(),
    D_Month: moment().add(1, "d").month() + 1,
    D_Year: moment().add(1, "d").year(),
    RoomCount: 1,
    AdultCount: [2],
    ChildCount: [0],
    ChildAges: [],
    NationalityCode: "",
    NationalityCodeLabel: "",
    Commissionable: false,
    IsSecretDealAgent: false,
    IsRetailAgent: false,
  },
  staySearchRequest: {
    HotelName: "",
    HotelId: 0,
    RegionName: "",
    RegionId: 0,
    ArrivalDate: moment().toDate(),
    DepartureDate: moment().add(1, "d").toDate(),
    A_Day: moment().date(),
    A_Month: moment().month() + 1,
    A_Year: moment().year(),
    D_Day: moment().add(1, "d").date(),
    D_Month: moment().add(1, "d").month() + 1,
    D_Year: moment().add(1, "d").year(),
    RoomCount: 1,
    AdultCount: [2],
    ChildCount: [0],
    ChildAges: [],
    NationalityCode: "",
    NationalityCodeLabel: "",
    Commissionable: false,
    IsSecretDealAgent: false,
    IsRetailAgent: false,
  },
  staySearchFilterRequest: {
    HotelName: "",
    HotelId: 0,
    LandmarkIds: "",
    DestinationGeoCodes: "",
    DestinationGeoCodesName: "",
    MinPrice: -1,
    MaxPrice: -1,
    NewRatings: [],
    DistrictIds: [],
    BrandIds: [],
    CategoryIds: [],
    HotelTypeIds: [],
    Refundable: false,
    parameterNameFilterCount: 0,
    starRatingFilterCount: 0,
    locationFilterCount: 0,
    budgetFilterCount: 0,
    moreFilterCount: 0,
    MealTypeIds: [],
    SortBy: 0,
  },
  searchId: -1,
  staySearchBudget: {
    MaxPrice: -1,
    MinPrice: -1,
    CurrencySymbol: "Rs",
  },
  homeSearchValidationError: {
    destination: false,
    stayDates: false,
    guest: false,
    nationality: false,
  },
  staySearchValidationError: {
    destination: false,
    stayDates: false,
    guest: false,
    nationality: false,
  },
  nationalityList: [],
  selectedHotel: {
    id: 0,
    curatedSearchResultKey: "",
    curatedHotelListId: 0,
  },
  selectedViewALLCategory: null,
  RefundablestaySearchBudget: {
    MaxPrice: -1,
    MinPrice: -1,
    CurrencySymbol: "Rs",
  },
};

export const HotelSearchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTIONTYPES.SET_HOTELSEARCH_REQUEST_ONREFRESH:
      const localSearchRequest = getStorageJsonParseData("searchRequest");
      if (localSearchRequest) {
        let refreshRequest = {
          HotelName: localSearchRequest.HotelName,
          HotelId: localSearchRequest.HotelId,
          RegionName: localSearchRequest.RegionName,
          RegionId: localSearchRequest.RegionId,
          ArrivalDate: localSearchRequest.ArrivalDate,
          DepartureDate: localSearchRequest.DepartureDate,
          A_Day: localSearchRequest.A_Day,
          A_Month: localSearchRequest.A_Month,
          A_Year: localSearchRequest.A_Year,
          D_Day: localSearchRequest.D_Day,
          D_Month: localSearchRequest.D_Month,
          D_Year: localSearchRequest.D_Year,
          RoomCount: localSearchRequest.RoomCount,
          AdultCount: localSearchRequest.AdultCount,
          ChildCount: localSearchRequest.ChildCount,
          ChildAges: localSearchRequest.ChildAges,
          NationalityCode: localSearchRequest.NationalityCode,
          NationalityCodeLabel: localSearchRequest.NationalityCodeLabel,
          Commissionable: localSearchRequest.Commissionable,
          IsSecretDealAgent: localSearchRequest.IsSecretDealAgent,
          IsRetailAgent: localSearchRequest.IsRetailAgent,
        };
        return {
          ...state,
          staySearchRequest: refreshRequest,
          homeSearchRequest: refreshRequest,
          searchId: localSearchRequest.SearchId
            ? localSearchRequest.SearchId
            : -1,
        };
      } else {
        return {
          ...state,
        };
      }

    case ACTIONTYPES.SET_HOTELSEARCH_REQUEST:
      let request = {
        HotelName: payload.HotelName,
        HotelId: payload.HotelId,
        RegionName: payload.RegionName,
        RegionId: payload.RegionId,
        ArrivalDate: payload.ArrivalDate,
        DepartureDate: payload.DepartureDate,
        A_Day: payload.A_Day,
        A_Month: payload.A_Month,
        A_Year: payload.A_Year,
        D_Day: payload.D_Day,
        D_Month: payload.D_Month,
        D_Year: payload.D_Year,
        RoomCount: payload.RoomCount,
        AdultCount: payload.AdultCount,
        ChildCount: payload.ChildCount,
        ChildAges: payload.ChildAges,
        NationalityCode: payload.NationalityCode,
        NationalityCodeLabel: payload.NationalityCodeLabel,
        Commissionable: payload.Commissionable,
        IsSecretDealAgent: payload.IsSecretDealAgent,
        IsRetailAgent: payload.IsRetailAgent,
      };
      setStorageJsonData("searchRequest", {
        ...request,
        SearchId: state.searchId,
        PageNumber: state.PageNumber,
      });
      let staySearchFilterRequest = clone(Request.filterRequest);
      staySearchFilterRequest.MinPrice = payload.MinPrice;
      staySearchFilterRequest.MaxPrice = payload.MaxPrice;
      staySearchFilterRequest.SortBy = payload.SortBy;

      let staySearchBudget = { ...state.staySearchBudget };
      staySearchBudget.MinPrice = payload.MinPrice;
      staySearchBudget.MaxPrice = payload.MaxPrice;

      return {
        ...state,
        staySearchRequest: request,
        homeSearchRequest: request,
        staySearchFilterRequest: staySearchFilterRequest,
        filters: [],
        staySearchBudget: staySearchBudget,
      };

    case ACTIONTYPES.SET_HOTELSEARCH_RESULT:
      let staySearchRequest = {};
      let staySearchFilterRequest_R = {};
      let searchId = { ...state.searchId };
      let selectedViewALLCategory = state.selectedViewALLCategory;
      if (payload && payload.data && payload.data.SearchDetails) {
        let localSearchRequest = getStorageJsonParseData("searchRequest");
        searchId = payload.data.SearchDetails.SearchId;

        localSearchRequest.RegionName =
          payload.data.SearchDetails.HotelId > -1
            ? payload.data.SearchDetails.Region.ParentName
            : payload.data.SearchDetails.Region.ParentName;
        if (localSearchRequest && localSearchRequest.SearchId !== searchId) {
          localSearchRequest.SearchId = searchId;
          setStorageJsonData("searchRequest", localSearchRequest);
        }

        if (
          payload.data.filter &&
          state.searchResponse &&
          state.searchResponse.HotelNames
        ) {
          payload.data.HotelNames = state.searchResponse.HotelNames;
        }
        //State is updated from filter api then use
        //homeSearchRequest state instate of staySearchRequest to aviod run time changes in main search
        if (!payload.isFilterSearch) {
          staySearchRequest = {
            ...state.staySearchRequest,
            RegionName: payload.data.SearchDetails.Region.ParentName,
          };
        } else {
          staySearchRequest = {
            ...state.homeSearchRequest,
            RegionName: payload.data.SearchDetails.Region.ParentName,
          };
        }
        if (payload.data.filter === undefined) {
          staySearchFilterRequest_R = { ...state.RefundablestaySearchBudget };
          staySearchFilterRequest_R.MinPrice =
            payload.data.SearchDetails.MinPrice;
          staySearchFilterRequest_R.MaxPrice =
            payload.data.SearchDetails.MaxPrice;
        }
        // Updated selectd View All Hotel Id and Name if Location filter changed
        if (selectedViewALLCategory) {
          if (payload.data.CuratedHotels) {
            let curatedKey = parseInt(selectedViewALLCategory.curatedKey);
            let curatedResult = payload.data.CuratedHotels[curatedKey];
            if (curatedKey === 21 || curatedKey === 24 || curatedKey === 25) {
              if (
                curatedResult &&
                curatedResult[0].Id !==
                  selectedViewALLCategory.curatedHotelListId
              ) {
                selectedViewALLCategory.curatedHotelListId =
                  curatedResult[0].Id;
                selectedViewALLCategory.curatedName =
                  "Near " + curatedResult[0].CarosalName;
              }
            }
          }
        }
      }

      return {
        ...state,
        searchResponse: payload.data,
        searchId: searchId,
        homeSearchValidationError: {
          destination: false,
          stayDates: false,
          guest: false,
          nationality: false,
        },
        staySearchValidationError: {
          destination: false,
          stayDates: false,
          guest: false,
          nationality: false,
        },
        staySearchRequest: staySearchRequest,
        homeSearchRequest: {
          ...state.homeSearchRequest,
          RegionName: payload.data.SearchDetails.Region.ParentName,
        },
        selectedViewALLCategory: selectedViewALLCategory,
        RefundablestaySearchBudget: staySearchFilterRequest_R,
      };

    case ACTIONTYPES.SET_FILTERS:
      let filters = [...state.filters];
      let filterRequest = { ...state.staySearchFilterRequest };
      let updatedRequest = {
        HotelName: payload.request.HotelName,
        HotelId: payload.request.HotelId,
        LandmarkIds: payload.request.LandmarkIds,
        DestinationGeoCodes: payload.request.DestinationGeoCodes,
        DestinationGeoCodesName: payload.request.DestinationGeoCodesName,
        MinPrice: payload.request.MinPrice,
        MaxPrice: filterRequest.MaxPrice,
        NewRatings: payload.request.NewRatings,
        DistrictIds: payload.request.DistrictIds,
        BrandIds: payload.request.BrandIds,
        CategoryIds: payload.request.CategoryIds,
        HotelTypeIds: payload.request.HotelTypeIds,
        Refundable: payload.request.Refundable,
        parameterNameFilterCount: filterRequest.parameterNameFilterCount,
        starRatingFilterCount: filterRequest.starRatingFilterCount,
        locationFilterCount: filterRequest.locationFilterCount,
        moreFilterCount: filterRequest.moreFilterCount,
        budgetFilterCount: filterRequest.budgetFilterCount,
        // sortByCount: filterRequest.sortByCount,
        MealTypeIds: payload.request.MealTypeIds,
        SortBy: payload.request.SortBy,
      };

      if (payload.item.type === "propertyName") {
        //Clear all applied Filters and add property filter
        filters = [];
        filters.push({
          type: payload.item.type,
          id: payload.item.id,
          value: payload.item.value,
        });
        updatedRequest.parameterNameFilterCount = 1;
        updatedRequest.starRatingFilterCount = 0;
        updatedRequest.locationFilterCount = 0;
        updatedRequest.budgetFilterCount = 0;
        updatedRequest.moreFilterCount = 0;
        updatedRequest.SortBy = 0;
      } else if (payload.item.type === "startRatings") {
        //On star rating selected and add startRatings filter
        if (payload.item.isSelected) {
          filters = filters.filter((item) => item.type !== "propertyName");
          updatedRequest.parameterNameFilterCount = 0;
          let index = filters.findIndex(
            (item) =>
              item.type === "startRatings" && item.id === payload.item.id
          );
          //Check if selected start Filter already added or not
          if (index === -1) {
            filters.push({
              type: payload.item.type,
              id: payload.item.id,
              value: payload.item.value,
            });
            updatedRequest.starRatingFilterCount =
              updatedRequest.starRatingFilterCount + 1;
          }
        } else {
          //Remove startRatings filter
          let index = filters.findIndex(
            (item) =>
              item.type === "startRatings" && item.id === payload.item.id
          );
          if (index !== -1) filters.splice(index, 1);
          updatedRequest.starRatingFilterCount =
            updatedRequest.starRatingFilterCount - 1;
        }
      } else if (payload.item.type === "budget") {
        updatedRequest.budgetFilterCount = 1;
        filters = filters.filter((item) => item.type !== "propertyName");
        updatedRequest.parameterNameFilterCount = 0;
        updatedRequest.MaxPrice = payload.request.MaxPrice;
        let budget = filters.find((a) => a.type === "budget");
        //Budget filtert already added then replace it's value with new one
        if (budget) {
          budget.value =
            payload.item.currencySymbol +
            payload.item.minPrice +
            "-" +
            payload.item.currencySymbol +
            payload.item.maxPrice +
            payload.item.symbol;
        } else {
          // Add Budget Filter
          filters.push({
            type: payload.item.type,
            value:
              payload.item.currencySymbol +
              payload.item.minPrice +
              "-" +
              payload.item.currencySymbol +
              payload.item.maxPrice +
              payload.item.symbol,
          });
        }
      } else if (payload.item.type === "location") {
        filters = filters.filter((item) => item.type !== "propertyName");
        updatedRequest.parameterNameFilterCount = 0;

        // if subtype Area remove pointOfInterest and geoCode filters
        if (payload.item.subType === "area") {
          filters = filters.filter(
            (item) => item.subType !== "pointOfInterest"
          );

          filters = filters.filter((item) => item.subType !== "geoCode");
          //if item selected the Add
          if (payload.item.isSelected) {
            let index = filters.findIndex(
              (item) =>
                item.type === payload.item.type &&
                item.subType === payload.item.subType &&
                item.id === payload.item.id
            );
            //check if item already added
            if (index === -1)
              filters.push({
                type: payload.item.type,
                subType: payload.item.subType,
                id: payload.item.id,
                value: payload.item.value,
              });
          } else {
            //On deselect Remove existing item
            let index = filters.findIndex(
              (item) => item.subType === "area" && item.id === payload.item.id
            );
            if (index !== -1) filters.splice(index, 1);
          }
        } else if (payload.item.subType === "pointOfInterest") {
          //If pointOfInterest selected, remove all location filter and add new item
          filters = filters.filter((item) => item.type !== "location");

          if (payload.item.value !== "") {
            filters.push({
              type: payload.item.type,
              subType: payload.item.subType,
              id: payload.item.id,
              value: payload.item.value,
            });
          }
        } else {
          //If geoCode selected, remove all location filter and add new item
          filters = filters.filter((item) => item.type !== "location");
          if (payload.item.value !== "") {
            filters.push({
              type: payload.item.type,
              subType: payload.item.subType,
              id: Math.floor(Math.random() * Date.now()),
              value: payload.item.value,
            });
          }
        }

        updatedRequest.locationFilterCount = filters.filter(
          (item) => item.type === "location"
        ).length;
      } else if (payload.item.type === "moreFilters") {
        filters = filters.filter((item) => item.type !== "propertyName");
        updatedRequest.parameterNameFilterCount = 0;
        //Remove all moreFilters and add new items
        filters = filters.filter((item) => item.type !== "moreFilters");

        payload.item.moreFilters.forEach((selectedFilter) => {
          filters.push({
            type: payload.item.type,
            subType: selectedFilter.subType,
            id: selectedFilter.id,
            value: selectedFilter.value,
          });
        });
        updatedRequest.moreFilterCount = payload.item.moreFilters.length;
      } else if (payload.item.type === "subRegionFilter") {
        filters = filters.filter((item) => item.type !== "propertyName");
        updatedRequest.parameterNameFilterCount = 0;
        filters.push({
          type: payload.item.type,
          id: payload.item.id,
          value: payload.item.value,
        });
      } // else if (payload.item.type === "sortBy") {
      //   filters = filters.filter((item) => item.type !== "propertyName");
      //   updatedRequest.parameterNameFilterCount = 0;
      //   updatedRequest.sortByCount = 1;
      //   // let index = filters.findIndex((item) => item.type === "sortBy");
      //   // if (index !== -1) filters.splice(index, 1);
      //   // filters.push({
      //   //   type: payload.item.type,
      //   //   id: payload.item.id,
      //   //   value: payload.item.value,
      //   // });
      //      }

      return {
        ...state,
        filters: filters,
        staySearchFilterRequest: updatedRequest,
      };

    case ACTIONTYPES.REMOVE_FILTERS:
      let stayFilters = [...state.filters];
      let stayFilterRequest = { ...state.staySearchFilterRequest };

      let updatedFilterRequest = {
        HotelName: "",
        HotelId: 0,
        LandmarkIds: payload.request.LandmarkIds,
        DestinationGeoCodes: payload.request.DestinationGeoCodes,
        DestinationGeoCodesName: payload.request.DestinationGeoCodesName,
        MinPrice: payload.request.MinPrice,
        MaxPrice: payload.request.MaxPrice,
        NewRatings: payload.request.NewRatings,
        DistrictIds: payload.request.DistrictIds,
        BrandIds: payload.request.BrandIds,

        CategoryIds: payload.request.CategoryIds,
        HotelTypeIds: payload.request.HotelTypeIds,
        Refundable: payload.request.Refundable,
        parameterNameFilterCount: stayFilterRequest.parameterNameFilterCount,
        starRatingFilterCount: stayFilterRequest.starRatingFilterCount,
        locationFilterCount: stayFilterRequest.locationFilterCount,
        moreFilterCount: stayFilterRequest.moreFilterCount,
        budgetFilterCount: stayFilterRequest.budgetFilterCount,
        MealTypeIds: payload.request.MealTypeIds,
        SortBy: payload.request.SortBy,
      };

      if (payload.item.type === "propertyName") {
        //clear all filters
        stayFilters = [];
        updatedFilterRequest.parameterNameFilterCount = 0;
        updatedFilterRequest.starRatingFilterCount = 0;
        updatedFilterRequest.locationFilterCount = 0;
        updatedFilterRequest.moreFilterCount = 0;
        updatedFilterRequest.budgetFilterCount = 0;
        updatedFilterRequest.SortBy = 0;
      } else if (payload.item.type === "startRatings") {
        //delete selected star rating Filter
        let index = stayFilters.findIndex(
          (item) => item.type === "startRatings" && item.id === payload.item.id
        );
        if (index !== -1) stayFilters.splice(index, 1);
        updatedFilterRequest.starRatingFilterCount =
          updatedFilterRequest.starRatingFilterCount - 1;
      } else if (payload.item.type === "budget") {
        //remove budget Filter
        stayFilters = stayFilters.filter((item) => item.type !== "budget");
        updatedFilterRequest.budgetFilterCount = 0;
      } else if (payload.item.type === "location") {
        //delete selected location Filter
        let index = stayFilters.findIndex(
          (item) => item.type === "location" && item.id === payload.item.id
        );
        if (index !== -1) stayFilters.splice(index, 1);
        updatedFilterRequest.locationFilterCount =
          updatedFilterRequest.locationFilterCount - 1;
      } else if (payload.item.type === "moreFilters") {
        //delete selected more filter
        let index = stayFilters.findIndex(
          (item) =>
            item.type === "moreFilters" &&
            item.subType === payload.item.subType &&
            item.id === payload.item.id
        );
        if (index !== -1) stayFilters.splice(index, 1);
        updatedFilterRequest.moreFilterCount =
          updatedFilterRequest.moreFilterCount - 1;
      } else if (payload.item.type === "subRegionFilter") {
        //delete sub region filter
        let index = stayFilters.findIndex(
          (item) =>
            item.type === "subRegionFilter" && item.id === payload.item.id
        );
        if (index !== -1) stayFilters.splice(index, 1);
      } // else if (payload.item.type === "sortBy") {
      //   let index = stayFilters.findIndex((item) => item.type === "sortBy");
      //   if (index !== -1) stayFilters.splice(index, 1);
      //   updatedFilterRequest.SortBy = 0;
      //   updatedFilterRequest.sortByCount = 0;
      // }
      return {
        ...state,
        filters: stayFilters,
        staySearchFilterRequest: updatedFilterRequest,
      };

    case ACTIONTYPES.SET_HOME_SEARCH_REQUEST:
      if (payload.type === "destination") {
        return {
          ...state,
          homeSearchRequest: {
            ...state.homeSearchRequest,
            RegionName: payload.RegionName,
            RegionId: payload.RegionId,
            HotelName: payload.HotelName,
            HotelId: payload.HotelId,
          },
          homeSearchValidationError: {
            ...state.homeSearchValidationError,
            destination: false,
          },
        };
      } else if (payload.type === "stayDates")
        return {
          ...state,
          homeSearchRequest: {
            ...state.homeSearchRequest,
            ArrivalDate: payload.ArrivalDate,
            DepartureDate: payload.DepartureDate,
            A_Day: moment(payload.ArrivalDate).date(),
            A_Month: moment(payload.ArrivalDate).month() + 1,
            A_Year: moment(payload.ArrivalDate).year(),
            D_Day: moment(payload.DepartureDate).date(),
            D_Month: moment(payload.DepartureDate).month() + 1,
            D_Year: moment(payload.DepartureDate).year(),
          },
          homeSearchValidationError: {
            ...state.homeSearchValidationError,
            stayDates: false,
          },
        };
      else if (payload.type === "guests")
        return {
          ...state,
          homeSearchRequest: {
            ...state.homeSearchRequest,
            RoomCount: payload.RoomCount,
            AdultCount: payload.AdultCount,
            ChildCount: payload.ChildCount,
            ChildAges: payload.ChildAges,
          },
          homeSearchValidationError: {
            ...state.homeSearchValidationError,
            guest: false,
          },
        };
      else if (payload.type === "nationality")
        return {
          ...state,
          homeSearchRequest: {
            ...state.homeSearchRequest,
            NationalityCode: payload.NationalityCode,
            NationalityCodeLabel: payload.NationalityCodeLabel,
          },
          homeSearchValidationError: {
            ...state.homeSearchValidationError,
            nationality: false,
          },
        };
      return state;

    case ACTIONTYPES.SET_STAY_SEARCH_REQUEST:
      if (payload.type === "destination") {
        return {
          ...state,
          staySearchRequest: {
            ...state.staySearchRequest,
            RegionName: payload.RegionName,
            RegionId: payload.RegionId,
            HotelName: payload.HotelName,
            HotelId: payload.HotelId,
          },
          staySearchValidationError: {
            ...state.staySearchValidationError,
            destination: false,
          },
        };
      } else if (payload.type === "stayDates")
        return {
          ...state,
          staySearchRequest: {
            ...state.staySearchRequest,
            ArrivalDate: payload.ArrivalDate,
            DepartureDate: payload.DepartureDate,
            A_Day: moment(payload.ArrivalDate).date(),
            A_Month: moment(payload.ArrivalDate).month() + 1,
            A_Year: moment(payload.ArrivalDate).year(),
            D_Day: moment(payload.DepartureDate).date(),
            D_Month: moment(payload.DepartureDate).month() + 1,
            D_Year: moment(payload.DepartureDate).year(),
          },
          staySearchValidationError: {
            ...state.staySearchValidationError,
            stayDates: false,
          },
        };
      else if (payload.type === "guests")
        return {
          ...state,
          staySearchRequest: {
            ...state.staySearchRequest,
            RoomCount: payload.RoomCount,
            AdultCount: payload.AdultCount,
            ChildCount: payload.ChildCount,
            ChildAges: payload.ChildAges,
          },
          staySearchValidationError: {
            ...state.staySearchValidationError,
            guest: false,
          },
        };
      else if (payload.type === "nationality")
        return {
          ...state,
          staySearchRequest: {
            ...state.staySearchRequest,
            NationalityCode: payload.NationalityCode,
            NationalityCodeLabel: payload.NationalityCodeLabel,
          },
          staySearchValidationError: {
            ...state.staySearchValidationError,
            nationality: false,
          },
        };
      return state;

    case ACTIONTYPES.SET_REFUNDABLE:
      return {
        ...state,
        staySearchFilterRequest: {
          ...state.staySearchFilterRequest,
          Refundable: payload,
        },
      };
    case ACTIONTYPES.SET_HOME_SERACH_VALIDATION_ERROR:
      let searchRequest = null;
      let searchValidationError = null;
      if (payload !== "stay") {
        searchRequest = state.homeSearchRequest;
        searchValidationError = { ...state.homeSearchValidationError };
      } else {
        searchRequest = state.staySearchRequest;
        searchValidationError = { ...state.staySearchValidationError };
      }

      if (searchRequest.HotelName === "" && searchRequest.RegionName === "") {
        searchValidationError.destination = true;
      }
      if (
        searchRequest.ArrivalDate === null ||
        searchRequest.DepartureDate === null
      ) {
        searchValidationError.stayDates = true;
      }
      if (searchRequest.RoomCount === 0) {
        searchValidationError.guest = true;
      } else {
        let isError = false;
        for (let count = 0; count < searchRequest.RoomCount; count++) {
          if (
            searchRequest.ChildAges.length > 0 &&
            searchRequest.ChildAges[count] &&
            searchRequest.ChildAges[count].find((a) => a === "") != null
          ) {
            isError = true;
            break;
          }
        }
        if (isError) {
          searchValidationError.guest = true;
        }
      }
      if (searchRequest.NationalityCode === "") {
        searchValidationError.nationality = true;
      }

      if (
        searchRequest.ArrivalDate !== null &&
        searchRequest.DepartureDate !== null &&
        searchRequest.ArrivalDate >= searchRequest.DepartureDate
      ) {
        searchValidationError.stayDates = true;
      }

      if (payload !== "stay") {
        return {
          ...state,
          homeSearchValidationError: searchValidationError,
        };
      } else {
        return {
          ...state,
          staySearchValidationError: searchValidationError,
        };
      }

    case ACTIONTYPES.SET_NATIONALITYLIST:
      return { ...state, nationalityList: payload };

    case ACTIONTYPES.CLEAR_SEARCHRESPONSE:
      return { ...state, searchResponse: {}, selectedHotel: null };

    case ACTIONTYPES.SET_SELECTEDHOTEL:
      return { ...state, selectedHotel: payload };

    case ACTIONTYPES.SET_VIEWALLCATEGORY:
      return { ...state, selectedViewALLCategory: payload };
    default:
      return state;
  }
};
