export const APPCONSTANTS = {
  CANCELLED: "Cancelled",
  PENDINGCANCELLATION: "Pending Cancellation",
  BOOKED: "Booked",
  PAID: "Paid",
  MANAGEBOOKING_TOTALRECORDS: 5,
  MANAGEBOOKING_ITEMPERPAGE: 10,
  SORT_ASC: 1,
  SORT_DESC: 2,
  CHARGE_BOOKING_ITEMTYPE: 2,
  ONACCOUNT: "OnAccount",
  CREDITCARD: "CreditCard",
  NETBANKING: "NetBanking",
  TRAVELPAY_EFT: "TravelPayEFT",
  TravelPayConnectorId: 26,
  PayTabConnectorId: 25,
  CCAvenueConnectorId: 20,
  CyberSourceConnectorId: 23,
  FlyRemitConnectorId: 27,
  BusinessUnitId: 2,
  AustraliaBusinessUnit: 3,
  TravelPayEFT_ShortCode: "TravelPayEFTAUD",
};
