import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./Reducers";
import thunk from "redux-thunk";

const composeEnhancers =
  (process.env.REACT_APP_ENV !== "prod" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
