import { ACTIONTYPES } from "../Actions/Types";
import {
  setCookiesData
} from "../../Utils/Helper";

const initialState = {
    RetailMarkUp: 0,
    LogoURL: ""
}

export const RetailInterfaceReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ACTIONTYPES.SET_USER_RETAIL_MARKUP: {
          setCookiesData("RetailMarkUp",  payload.RetailMarkUp);
          setCookiesData("LogoURL",  payload.LogoURL);
          return {
            ...state,
            RetailMarkUp: payload.RetailMarkUp,
            LogoURL: payload.LogoURL
          };
        }
        default:
          return state;
      }
}