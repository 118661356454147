import { ACTIONTYPES } from "../Actions/Types";

const initialState = {
  user: {
    LoginId: "",
    VisitId: "",
    SeqNumber: "",
    PartnerName: "",
    UserName: "",
    PartnerId: "",
    ParentId: "",
    SiteId: "",
    SessionId: "",
    RealName: "",
    NationalityCode: "",
    BussinessUnitId: "",
    isBUWiseData: false,
    Commissionable: false,
  },
  showLoading: false,
  error: "",
};

export const AuthReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ACTIONTYPES.LOGIN_USER:
      return { ...state, showLoading: true, error: "" };
    case ACTIONTYPES.LOGIN_USER_SUCCESS:
      const loginData = {
        LoginId: payload.LoginId,
        VisitId: payload.VisitId,
        SeqNumber: payload.cookies.SeqNumber,
        PartnerName: payload.cookies.PartnerName,
        UserName: payload.cookies.UserName,
        PartnerId: payload.cookies.PartnerId,
        ParentId: payload.cookies.ParentId,
        SiteId: payload.cookies.SiteId,
        SessionId: payload.cookies.SessionId,
        RealName: payload.cookies.RealName,
        NationalityCode: payload.NationalityCode,
        BussinessUnitId: payload.BussinessUnitId,
        isBUWiseData: payload.isPdfPresent,
        Commissionable: payload.Commissionable,
      };
      return {
        ...state,
        user: loginData,
        showLoading: false,
        error: "",
      };
    case ACTIONTYPES.LOGIN_USER_FAILURE:
      return {
        ...state,
        showLoading: false,
        error: payload,
      };
    case ACTIONTYPES.SET_LOGIN_USER:
      return { ...state, user: payload };

    case ACTIONTYPES.LOGOUT_USER:
      return {
        ...state,
        user: {
          LoginId: "",
          VisitId: "",
          SeqNumber: "",
          PartnerName: "",
          UserName: "",
          PartnerId: "",
          ParentId: "",
          SiteId: "",
          SessionId: "",
          RealName: "",
          NationalityCode: "",
          BussinessUnitId: "",
          Commissionable: false,
        },
      };
    default:
      return state;
  }
};
