import { combineReducers } from "redux";
import { ACTIONTYPES } from "../Actions/Types";
import { AuthReducer } from "./AuthReducer";
import { HotelSearchReducer } from "./HotelSearchReducer";
import { RefreshAvailabilityReducer } from "./RefreshAvailabilityReducer";
import { GuestDetailsReducer } from "./GuestDetailsReducer";
import { RetailInterfaceReducer } from "./RetailInterfaceReducer";
import { AdvanceSettingsReducer } from "./AdvanceSettingsReducer";
// Combine all reducers.
const appReducer = combineReducers({
  auth: AuthReducer,
  hotelSearch: HotelSearchReducer,
  refreshAvailability: RefreshAvailabilityReducer,
  guestsInfo: GuestDetailsReducer,
  userRetailMarkup: RetailInterfaceReducer,
  advanceSettings: AdvanceSettingsReducer
});
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === ACTIONTYPES.LOGOUT_USER) state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
