import React, { lazy } from "react";
import {
  Routes as Switch,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Cookies from "universal-cookie";

const Home = lazy(() => import("./Home"));
const Stay = lazy(() => import("./Stay"));
const GuestDetails = lazy(() => import("./GuestDetails"));
const CheckOut = lazy(() => import("./CheckOut"));
const PaymentRedirect = lazy(() => import("./PaymentRedirect"));
const BookingConfirmation = lazy(() => import("./BookingConfirmation"));
const ManageBookings = lazy(() => import("./ManageBookings"));
const AgencyDetails = lazy(() => import("./AgencyDetails"));
const FinanceDetails = lazy(() => import("./FinanceDetails"));
const Transfer = lazy(() => import("./Transfer"));
const TransferRedirect = lazy(() => import("./TransferRedirect"));
const Error = lazy(() => import("./Error"));
const Login = lazy(() => import("./Login"));
const AgencyPaymentRegistration = lazy(() =>
  import("./AgencyPaymentRegistration")
);

export default function Routes(props) {
  return (
    <div className="RouteMainSection">
      <Switch>
        <Route
          path="/Home"
          exact
          element={
            <ProtectedRoutes>
              <Home isLoading={false} />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/Stays"
          exact
          element={
            <ProtectedRoutes>
              <Stay />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/Guestdetails"
          exact
          element={
            <ProtectedRoutes>
              <GuestDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/Checkout"
          exact
          element={
            <ProtectedRoutes>
              <CheckOut />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/PaymentRedirect"
          exact
          element={
            <ProtectedRoutes>
              <PaymentRedirect />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/BookingConfirmation"
          exact
          element={
            <ProtectedRoutes>
              <BookingConfirmation />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/ManageBookings"
          exact
          element={
            <ProtectedRoutes>
              <ManageBookings />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/AgencyDetails"
          exact
          element={
            <ProtectedRoutes>
              <AgencyDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/FinanceDetails"
          exact
          element={
            <ProtectedRoutes>
              <FinanceDetails />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/Transfer"
          exact
          element={
            <ProtectedRoutes>
              <Transfer />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/TransferRedirect"
          exact
          element={
            <ProtectedRoutes>
              <TransferRedirect />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/AgencyPaymentRegistration"
          exact
          element={
            <ProtectedRoutes>
              <AgencyPaymentRegistration />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/Error"
          exact
          element={
            <ProtectedRoutes>
              <Error />
            </ProtectedRoutes>
          }
        />
        <Route path="/" exact element={<Login />} />
        <Route path="/Login" exact element={<Login />} />
        <Route path="*" exact element={<Login />} />
      </Switch>
    </div>
  );
}

function ProtectedRoutes({ children }) {
  const location = useLocation();
  //Added Condition to redirect from wordpress domain to Qrate Domain (iframe)
  //Avoid this condition for Stay and TransferRedirect (iframe)
  if (
    window.top.location !== window.self.location &&
    window.self.location.pathname !== "/Stays" &&
    window.self.location.pathname !== "/TransferRedirect"
  ) {
    window.top.location = window.self.location;
  }

  var cookies = new Cookies();
  let token = cookies.get("Token");
  if (token) return children;
  else if (location.pathname !== "/Login")
    return <Navigate to="/Login" replace />;
}
