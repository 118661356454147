import { ACTIONTYPES } from "./Types";
import {
  setLoginCookies,
  removeLoginCookies,
  setLocalStorageData,
  clearStorage,
} from "../../Utils/Helper";
import { CommonAPIService } from "../../Services/CommonService";
import {
  LOGINURL,
  GET_PARTNER_CONFIG,
  UPDATE_LOGO,
} from "../../Services/APIUrl";

export const loginUser = (data, navigate) => async (dispatch) => {
  dispatch({ type: ACTIONTYPES.LOGIN_USER });
  //API call
  const response = await CommonAPIService.post(LOGINURL, data);
  //if success store requied data in local storage.
  //Dispatch action in reducer that will store required parameters in Cookies.

  if (response.success && response.data) {
    localStorage.clear();
    setLoginCookies(response.data);
    setLocalStorageData("loginAgency", data.Agency);
    setLocalStorageData("loginUserName", data.UserName);
    dispatch({ type: ACTIONTYPES.LOGIN_USER_SUCCESS, payload: response.data });
    dispatch({
      type: ACTIONTYPES.SET_PARTNER_CONFIG,
      payload: response.data.PartnerConfig,
    });
    dispatch({
      type: ACTIONTYPES.SET_USER_RETAIL_MARKUP,
      payload: {
        RetailMarkUp: response.data.UserRetailMarkupPrice,
        LogoURL: response.data.LogoURL,
      },
    });
    //Set Ddefault Nationality.
    //Default Nationality for Agency is set from BO. We need to bind it to Front end.

    if (response.data.NationalityCode) {
      dispatch({
        type: ACTIONTYPES.SET_HOME_SEARCH_REQUEST,
        payload: {
          type: "nationality",
          NationalityCode: response.data.NationalityCode,
          NationalityCodeLabel: "",
        },
      });
      dispatch({
        type: ACTIONTYPES.SET_STAY_SEARCH_REQUEST,
        payload: {
          type: "nationality",
          NationalityCode: response.data.NationalityCode,
          NationalityCodeLabel: "",
        },
      });
    }

    //Once login is successfull, we will navigate to Home Route.
    navigate("/Home");
  } else {
    let error = "Something went wrong. Please try again after sometime.";
    if (response.data === "" && response.status === "") {
      error =
        "Unable to log in due to temporary network issue. Please try again after few minutes.";
    } else if (response.data === "Invalid_User") {
      error = "Invalid Agency ID or Username or Password";
    }
    dispatch({ type: ACTIONTYPES.LOGIN_USER_FAILURE, payload: error });
  }
};

export const setLoginUser = (data) => (dispatch) => {
  dispatch({ type: ACTIONTYPES.SET_LOGIN_USER, payload: data });
};

export const setLoginUserFailure = (data) => (dispatch) => {
  dispatch({ type: ACTIONTYPES.LOGIN_USER_FAILURE, payload: data });
};

export const LogoutUser = () => (dispatch) => {
  clearStorage();
  removeLoginCookies();
  if (
    process.env.REACT_APP_ENV === "dev" ||
    process.env.REACT_APP_ENV === "qa" ||
    process.env.REACT_APP_ENV === "prod"
  )
    window.location.replace(process.env.REACT_APP_WORDPRESS_URL);
  dispatch({ type: ACTIONTYPES.LOGOUT_USER });
};
