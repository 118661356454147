import { ACTIONTYPES } from "./Types";

export const setHotelSearchRequest = (data) => (dispatch) => {
  dispatch({ type: ACTIONTYPES.SET_HOTELSEARCH_REQUEST, payload: data });
};

export const setHotelSearchRequestOnRefresh = () => (dispatch) => {
  dispatch({
    type: ACTIONTYPES.SET_HOTELSEARCH_REQUEST_ONREFRESH,
  });
};

export const setHotelSearchResult = (data, isFilterSearch) => (dispatch) => {
  let payload = { data: data, isFilterSearch: isFilterSearch };
  dispatch({
    type: ACTIONTYPES.SET_HOTELSEARCH_RESULT,
    payload: payload,
  });
};

export const setFilters = (data) => (dispatch) => {
  dispatch({ type: ACTIONTYPES.SET_FILTERS, payload: data });
};

export const removeFilters = (data) => (dispatch) => {
  dispatch({ type: ACTIONTYPES.REMOVE_FILTERS, payload: data });
};

export const setHomeSearchRequest = (data) => (dispatch) => {
  dispatch({ type: ACTIONTYPES.SET_HOME_SEARCH_REQUEST, payload: data });
};

export const setStaySearchRequest = (data) => (dispatch) => {
  dispatch({ type: ACTIONTYPES.SET_STAY_SEARCH_REQUEST, payload: data });
};

export const setRefundable = (data) => (dispatch) => {
  dispatch({ type: ACTIONTYPES.SET_REFUNDABLE, payload: data });
};

export const setHomeSearchValidationError = (data) => (dispatch) => {
  dispatch({
    type: ACTIONTYPES.SET_HOME_SERACH_VALIDATION_ERROR,
    payload: data,
  });
};

export const setNationalityList = (data) => (dispatch) => {
  dispatch({
    type: ACTIONTYPES.SET_NATIONALITYLIST,
    payload: data,
  });
};

export const clearSearchResponse = (data) => (dispatch) => {
  dispatch({ type: ACTIONTYPES.CLEAR_SEARCHRESPONSE });
};

export const setSelectedHotel = (data) => (dispatch) => {
  dispatch({
    type: ACTIONTYPES.SET_SELECTEDHOTEL,
    payload: data,
  });
};

export const setViewAllCategory = (data) => (dispatch) => {
  dispatch({
    type: ACTIONTYPES.SET_VIEWALLCATEGORY,
    payload: data,
  });
};
