import { ACTIONTYPES } from "../Actions/Types";
const initialState = {
  RoomsApi: [],
  Rooms: [],
  Guests: {},
  isOnAccount: false,
  isCCOn: false,
  BuyingPrice: -1,
  GuestTitle: [],
  AgentRef: "",
  isPolicyAccepted: false,
  SpecialRequest: [],
};

export const GuestDetailsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ACTIONTYPES.SET_ROOMS_REQUEST: {
      return {
        ...state,
        RoomsApi: payload.RoomsApi,
        GuestTitle: payload.GuestTitle,
        AgentRef: payload.AgentRef,
        SpecialRequest: payload.SpecialRequest,
        isPolicyAccepted: payload.isPolicyAccepted,
      };
    }
    case ACTIONTYPES.SET_ROOM_OBJECT: {
      return {
        ...state,
        Rooms: payload,
        AgentRef: payload[0].AgentRef,
      };
    }
    case ACTIONTYPES.SET_CONFIRMGUESTDETAILS_RESULT:
      return {
        ...state,
        Guests: payload.GuestsDetails,
        isOnAccount: payload.isOnAccount,
        isCCOn: payload.isCC,
        BuyingPrice: payload.BuyingPrice,
        canPayOnAccount: payload.canPayOnAccount,
      };
    case ACTIONTYPES.SET_POLICYACCEPTED:
      return {
        ...state,
        isPolicyAccepted: payload,
      };
    default:
      return state;
  }
};
