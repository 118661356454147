import { ACTIONTYPES } from "./Types";
import { CommonAPIService } from "../../Services/CommonService";
import { UPDATE_USER_RETAIL_MARKUP, UPDATE_LOGO } from "../../Services/APIUrl";

export const setUserRetailMarkup = (request, navigate) => async (dispatch) => {
    const response = await CommonAPIService.post(UPDATE_USER_RETAIL_MARKUP, request);
    if (response.success && response.data) {
        delete request.UserId;
        delete request.RetailMarkUp;
        request.IsAgencyRetailMarkUpAvailable = response.data.RetailMarkUp > 0;
        const rsp = await CommonAPIService.post(UPDATE_LOGO, request);
        if (rsp.success && rsp.data)
        {
            dispatch({ type: ACTIONTYPES.SET_USER_RETAIL_MARKUP, payload: {RetailMarkUp: response.data.RetailMarkUp, LogoURL: rsp.data }});
            navigate("/Home");
        }
    }
}

export const setUserRetailMarkupOnRefresh = (data) => async (dispatch) => {
    dispatch({ type: ACTIONTYPES.SET_USER_RETAIL_MARKUP, payload: data });
}